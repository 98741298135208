import {
    Body1,
    Button,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Subtitle1,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { useEffect, useState } from 'react';
//import { useDispatch } from 'react-redux';
//import { usePlugins } from '../../libs/hooks';
//import { AlertType } from '../../libs/models/AlertType';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
//import { addAlert } from '../../redux/features/app/appSlice';
//import { Plugin, PluginAuthRequirements } from '../../redux/features/plugins/PluginsState';
import { AppsAddIn24, Dismiss24 } from '../shared/BundledIcons';
//import { AddPluginCard } from './cards/AddPluginCard';

const useClasses = makeStyles({
    root: {
        maxWidth: '1052px',
        height: '852px',
        width: 'fit-content',
        display: 'flex',
    },
    title: {
        ...shorthands.margin(0, 0, '12px'),
    },
    description: {
        ...shorthands.margin(0, 0, '12px'),
    },
    dialogContent: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflowY: 'auto',
        rowGap: '24px',
        columnGap: '24px',
        ...shorthands.padding('12px', '2px', '12px'),
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
    },
});

export const PluginGallery: React.FC = () => {
    const classes = useClasses();
    // const dispatch = useDispatch();

    //const { plugins } = useAppSelector((state: RootState) => state.plugins);
    const { serviceInfo } = useAppSelector((state: RootState) => state.app);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const [open, setOpen] = useState(false);

    //const [hostedPlugins, setHostedPlugins] = useState([] as Plugin[]);
    //const { getPluginManifest } = usePlugins();

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversations[selectedId], open, serviceInfo.availablePlugins]);

    return (
        <Dialog
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogTrigger>
                <Button
                    data-testid="pluginButton"
                    style={{ color: 'white' }}
                    appearance="transparent"
                    icon={<AppsAddIn24 color="white" />}
                    title="Plugins Gallery"
                    aria-label="Plugins Gallery"
                >
                    Plugins
                </Button>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    data-testid="closeEnableCCPluginsPopUp"
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24 />}
                                />
                            </DialogTrigger>
                        }
                    >
                        <Subtitle1 block className={classes.title}>
                            Enable Chat Copilot Plugins
                        </Subtitle1>
                        <Body1 as="p" block className={classes.description}>
                            Authorize plugins and have more powerful bots!
                        </Body1>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        {
                            //<AddPluginCard />
                        }
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
